<script setup>
  import { formData, rules, formatPhoneNumber, disabledDate, virtualFormData } from './zindagi-popup'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, toRef } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['nextStep'])

  const formBoxRef = ref(null)

  // 监听手机号输入
  const phoneNoRef = toRef(formData, 'formatPhoneNo')
  watch(phoneNoRef, (newValue) => {
    formData.formatPhoneNo = formatPhoneNumber(newValue)
  })

  // 处理下一步操作
  const handleNext = (formBoxRef, formData) => {
    rules.value.invoicePicture = [{ required: true, message: 'Please upload invoice picture', trigger: 'blur' }]
    rules.value.warrantyCardPicture = [{ required: true, message: 'Please upload warranty card picture', trigger: 'blur' }]
    // console.log(formData, 'formData')
    formBoxRef.validate((valid, fields) => {
      if (valid) {
        console.log('Validation successful')
        // 这里可以执行其他逻辑，如提交表单数据等
        $emit('nextStep')
      }
    })
  }

  onMounted(() => {
    
  })
</script>

<template>
  <div class="rowCC">
    <div class="columnSS form-content-box">
      <div class="title mb10">Personal details</div>
      <el-form ref="formBoxRef" class="form-box" label-position="top" label-width="auto" :model="formData" :rules="rules" hide-required-asterisk>
        <el-form-item label="Name" prop="name">
          <el-input v-model="formData.name" placeholder="Enter your name" @blur="formData.name = $event.target.value.trim()" clearable />
        </el-form-item>
        <el-form-item label="Phone No" prop="formatPhoneNo">
          <el-input maxlength="13" v-model="formData.formatPhoneNo" placeholder="03xx-xxxx-xxx" clearable />
        </el-form-item>
        <el-form-item label="ID Card No (Do not use Dashes)" prop="idCardNo">
          <!-- oninput="value=value.replace(/[^\d]/g, '')" -->
          <el-input type="number" v-model="formData.idCardNo" placeholder="Enter ID Card No" clearable />
        </el-form-item>
        <el-form-item label="ID Card Issuance Date" prop="idCardIssuanceDate">
          <el-date-picker
            v-model="formData.idCardIssuanceDate"
            style="width: 100%"
            type="date"
            placeholder="Select Date"
            value-format="YYYY-MM-DD"
            :disabledDate="disabledDate"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item>
          <div class="rowCC wh100 mt20">
            <el-button type="primary" class="form-btn" @click="handleNext(formBoxRef, formData)">Next</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @import '@/assets/css/element-custom.less';

  .form-content-box {
    width: 100%;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }
  }

  .form-box {
    width: 100%;
    .form-btn {
      min-width: 120px;
      height: 42px;
      border-radius: 24px;
      background-color: var(--btnBgColor);
      color: var(--button-text-color);
    }
  }
</style>
