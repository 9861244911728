<script setup>
  import { fluttlerData, addMessageListener, postMessageToFlutter } from './post-message'
  import { formData, virtualFormData, initialFormData, formatPhoneNumber } from './zindagi-popup'
  import personalDetails from './personal-details'
  import purchaseDetails from './purchase-details'
  import registrationAffirm from './registration-affirm'
  import { getCurrentInstance, onMounted, onUpdated, ref, reactive, defineEmits, computed, watch, watchEffect } from 'vue'
  import { ElMessage } from 'element-plus'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['informSuc'])

  const data = reactive({
    dialogFormVisible: false,
    dialogFormModalSuc: false,
    dialogModal: true, // 是否需要遮罩层
    closeOnClickmodal: false, // 是否可以通过点击 modal 关闭 Dialog
    closeOnPressEscape: false, // 是否可以通过按下 ESC 关闭 Dialog
    step: 0,
    hasEnteredBefore: false, // 是否已经录入过
    loading: false,
    commitRes: {}
  })

  // 计算对话框宽度
  const dialogWidth = computed(() => {
    if (window.innerWidth < 768) {
      // 移动端宽度为父元素宽度的80%
      return `${window.innerWidth * 0.9}px`
    }
    // PC 端固定宽度为 540px
    return '540px'
  })

  // 上一步
  const prevStep = () => {
    if (data.step > 0) {
      data.step--
    }
  }

  // 下一步
  const nextStep = () => {
    if (data.step > 1) {
      console.log('提交表单')
      data.loading = true
      if (formData.type === 'add') {
        addCashback()
      } else {
        // emitCashback()
        addCashback()
        console.log('编辑')
      }
      return
    }
    data.step++
  }

  // 提交返现记录
  const addCashback = () => {
    // 提交成功
    const parms = {
      edit: formData.type === 'edit',
      old_request_id: formData.id,
      name: formData.name,
      phone_no: formData.phoneNo,
      user_id: userStore.userInfo.userId,
      id_card_no: formData.idCardNo,
      id_card_issuance_date: formData.idCardIssuanceDate,
      ac_quantity: formData.ACQuantity,
      purchase_date: formData.purchaseDate,
      installation_request_number: [formData.installationRequestNumber],
      installation_complete_date: formData.installationCompleteDate,
      invoice_picture: formData.invoicePictureList, // single image file
      warranty_card_picture: formData.warrantyCardPictureList // multiple images
    }
    console.log('parms新增返现记录', parms)
    api.activity
      .addCashback(parms)
      .then((result) => {
        console.log('addCashback', result)
        data.loading = false
        if (result.code === 200) {
          data.commitRes = result.data
          dialogFormModalSuc()
          resetData()
        } else {
          ElMessage.error(`${result.data.message || result.message}`)
        }
      })
      .catch((err) => {
        data.loading = false
      })
  }

  // 编辑返现记录=>编辑逻辑走新增.预留逻辑
  const emitCashback = () => {
    // 提交成功
    const parms = {
      edit: formData.type === 'edit',
      old_request_id: formData.id,
      name: formData.name,
      phone_no: formData.phoneNo,
      user_id: userStore.userInfo.userId,
      id_card_no: formData.idCardNo,
      id_card_issuance_date: formData.idCardIssuanceDate,
      ac_quantity: formData.ACQuantity,
      purchase_date: formData.purchaseDate,
      installation_request_number: [formData.installationRequestNumber],
      installation_complete_date: formData.installationCompleteDate,
      invoice_picture: formData.invoicePictureList, // single image file
      warranty_card_picture: formData.warrantyCardPictureList // multiple images
    }
    console.log('parms编辑返现记录', parms)
    api.activity
      .emitCashback(parms, formData.id)
      .then((result) => {
        console.log('emitCashback', result)
        data.loading = false
        if (result.code === 200) {
          data.commitRes = result.data
          dialogFormModalSuc()
          resetData()
        } else {
          ElMessage.error(`${result.data.message || result.message}`)
        }
      })
      .catch((err) => {
        data.loading = false
      })
  }

  // 弹出成功弹窗
  const dialogFormModalSuc = () => {
    data.dialogFormVisible = false
    data.dialogFormModalSuc = true
  }

  // 弹出表单弹窗
  const zindagiPopupShow = (obj) => {
    console.log('弹出活动弹窗')
    formData.type = obj.type
    updatedFromData(obj.curItem)
    if (formData.type === 'add' && obj.curItem.phoneNo) {
      data.hasEnteredBefore = true
      data.step = 1
      console.log('判定是第二次进入', data.step, data.hasEnteredBefore)
    }
    data.dialogFormVisible = true
  }

  // 关闭弹窗
  const closePopup = () => {
    data.dialogFormVisible = false
    data.dialogFormModalSuc = false
    resetData()
    // 通知f关闭弹窗
    // postMessageToFlutter({ name: 'ReturnFlutter', data: '' })
  }

  const closePopupSuc = () => {
    $emit('informSuc')
    closePopup()
  }

  // 重置表单
  const resetData = () => {
    data.step = 0
    data.hasEnteredBefore = false
    console.log('重置表单触发更新')
    updatedFromData(initialFormData)
  }

  // 更新表单数据
  const updatedFromData = (data) => {
    // console.log('更新formData', formData, data)
    for (const key in data) {
      const formattedKey = key.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase())
      if (Object.prototype.hasOwnProperty.call(formData, formattedKey)) {
        formData[formattedKey] = data[key]
      }
    }

    formData.formatPhoneNo = formatPhoneNumber(formData.phoneNo)
    if (formData.type === 'edit') {
      formData.invoicePictureList = JSON.parse(formData.invoicePicture)
      formData.warrantyCardPictureList = JSON.parse(formData.warrantyCardPicture)
      const installationRequestNumbers = JSON.parse(formData.installationRequestNumber)
      formData.installationRequestNumber = installationRequestNumbers[0]
    }

    console.log('更新后的formData', formData, data.step)
  }

  // 自动打开页面
  const antoHandle = (showType) => {
    console.log(showType, 'showType')
    if (showType === '1') {
      data.dialogFormVisible = true
      initPostmessage()
    }
    updatedFromData(virtualFormData, data.type)
  }

  // 初始化接收消息
  const initPostmessage = () => {
    addMessageListener((flutterData) => {
      if (flutterData.type === 'CommonParams') {
        console.log('接收CommonParams222222', flutterData)
      }
    })
  }

  onMounted(() => {
    const showType = useRoute().query.show || '0'
    fluttlerData.fluttlerType = useRoute().query.fluttlerType || ''

    // 测试
    // zindagiPopupShow({
    //   type: 'add',
    //   curItem: virtualFormData
    // })
    // dialogFormModalSuc()
  })

  // 导出方法
  defineExpose({
    zindagiPopupShow,
    closePopup
  })
</script>

<template>
  <div>
    <el-dialog
      v-model="data.dialogFormVisible"
      class="zindagi-dialog-box"
      center
      :width="dialogWidth"
      :modal="data.dialogModal"
      :close-on-click-modal="data.closeOnClickmodal"
      :close-on-press-escape="data.closeOnPressEscape"
      :before-close="closePopup"
      :destroy-on-close="true"
    >
      <template #header>
        <div class="title">Haier AC Cash Back Registration</div>
        <div class="sub-title">
          <p v-if="data.step === 0">Provide Basic details of yourself to Get Started</p>
          <p v-else-if="data.step === 1">
            <span class="col2">Dear {{ formData.name }} </span> Provide Basic Details Regarding Purchase
          </p>
          <p v-else-if="data.step === 2">Please provide your consent before proceeding</p>
        </div>
      </template>
      <div class="from-box"  v-loading="data.loading">
        <personalDetails v-if="data.step === 0" @nextStep="nextStep" />
        <purchaseDetails v-else-if="data.step === 1" :hasEnteredBefore="data.hasEnteredBefore" @nextStep="nextStep" @prevStep="prevStep" />
        <registration-affirm v-else-if="data.step === 2" @nextStep="nextStep" @prevStep="prevStep" />
      </div>
    </el-dialog>

    <!-- 提交成功 -->
    <el-dialog v-model="data.dialogFormModalSuc" class="modal-suc-box" center :width="dialogWidth" :modal="data.dialogModal" :before-close="closePopupSuc">
      <div class="columnCC model-suc-box">
        <JLImg class="img" src="492dafa8.png" />
        <div class="title mt10">Request Submitted</div>
        <div class="mt10 copy-box rowCC">
          <p>Your Request ID Is:</p>
          <p class="uid-text ml5 mr5">{{ data.commitRes.id }}</p>
          <p class="copy-text" @click="JLTools.copyText(data.commitRes.id, 'Copy success')"></p>
        </div>
        <div class="text mt10 mb20">We will review your cashback request, and upon confirmation of its accuracy, the reward will be promptly sent to your wallet.</div>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
  :deep(.el-dialog) {
    --el-dialog-margin-top: 5vh;
  }
  .zindagi-dialog-box {
    /* background: rgba(250, 250, 250, 1) !important; */
    margin-top: 100px;
    .title {
      font-size: 24px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(49, 42, 80, 0.72);
      text-align: center;
      .col2 {
        color: #0c5ca8;
      }
    }
    .from-box {
      width: 440px;
      margin: 0 auto;
    }
  }

  .modal-suc-box {
    color: rgba(0, 0, 0, 1);
    .img {
      width: 100px;
      height: 100px;
      margin-top: -30px;
    }
    .title {
      font-size: 32px;
      font-weight: 600;
      color: rgba(34, 205, 133, 1);
    }
    .copy-box {
      color: rgba(95, 94, 94, 0.87);
      font-weight: 600;
      .id-text {
        margin: 0 5px;
      }
      .copy-text {
        width: 24px;
        height: 24px;
        background: url('https://cdn.pricoo.pk/0179f121.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    .text {
      text-align: center;
      color: rgba(95, 94, 94, 0.64);
    }
  }

  /* 移动端样式 */
  @media (max-width: 767px) {
    .zindagi-dialog-box .from-box {
      width: 100%;
    }
  }
</style>
