import { onMounted, ref, reactive, watch, toRef } from 'vue'

// 初始的表单数据
export const initialFormData = {
  id: '',
  type: 'add',
  name: '',
  phoneNo: '', // 手机号
  formatPhoneNo: '', // 格式化手机号
  idCardNo: '', // 卡号
  idCardIssuanceDate: '', // 卡发行日期
  ACQuantity: 1, // 购买数量
  purchaseDate: '', // 购买日期
  invoicePicture: '', // 上传图片后缀
  warrantyCardPicture: '', // 上传图片
  invoicePictureList: '', // 上传图片后缀列表
  warrantyCardPictureList: '', // 上传图片列表
  installationRequestNumber: '', // 安装编号
  installationRequestNumberList: [], // 多个安装编号
  installationCompleteDate: '', // 安装完成日期
  affirmCheckbox: false
}

// 假数据
export const virtualFormData = {
  id: '',
  name: 'John Doe',
  phoneNo: '03001234567',
  formatPhoneNo: '0300-1234-567',
  idCardNo: '1234567890123',
  idCardIssuanceDate: '2022-04-20',
  ACQuantity: 1,
  purchaseDate: '2022-04-15',
  invoicePicture: 'invoice.jpg',
  warrantyCardPicture: 'warranty_card.jpg',
  invoicePictureList: ['invoice.jpg'], // 上传图片后缀列表
  warrantyCardPictureList: ['warranty_card.jpg'], // 上传图片列表
  // invoicePictureList: [], // 上传图片后缀列表
  // warrantyCardPictureList: [], // 上传图片列表
  installationRequestNumber: 'ABC1234567890123',
  installationRequestNumberList: ['ABC1234567890123', 'XYZ9876543210987'],
  installationCompleteDate: '2022-04-25',
  affirmCheckbox: true
}

// 响应数据
export const formData = reactive({ ...initialFormData })

export const rules = ref({
  name: [
    { required: true, message: 'Name field is required', trigger: 'blur' },
    { pattern: /^[a-zA-Z\s]*$/, message: 'Please enter the correct name', trigger: 'blur' },
    { min: 3, max: 25, message: 'Length should be 3 to 25', trigger: 'blur' },
  ],
  formatPhoneNo: [
    { required: true, message: 'Please enter your mobile number', trigger: 'blur' },
    { pattern: /^03\d{2}-\d{4}-\d{3}$/, message: 'Please enter 11 digit phone number. The value should be in 03xx-xxxx-xxx format', trigger: 'blur' }
  ],
  idCardNo: [
    { required: true, message: 'ID card field is required', trigger: 'blur' },
    { min: 13, max: 13, message: 'ID card field should have exactly 13 digits', trigger: 'blur' }
  ],
  idCardIssuanceDate: [{ required: true, message: 'Please select ID card issue date', trigger: 'blur' }],
  ACQuantity: [{ required: true, message: 'Please enter quantity in numbers', trigger: 'blur' }],
  installationRequestNumber: [
    { required: true, message: 'Please enter installation request number', trigger: 'blur' },
    { pattern: /^[a-zA-Z0-9]{16,20}$/, message: 'Please enter a 16 or 20 characters Installation Request Number', trigger: 'blur' }
  ],
  invoicePicture: [{ required: true, message: 'Please upload invoice picture' }],
  warrantyCardPicture: [{ required: true, message: 'Please upload warranty card picture' }],
  invoicePictureList: [{ required: true, message: 'Please upload invoice picture' }],
  warrantyCardPictureList: [{ required: true, message: 'Please upload warranty card picture' }],
  purchaseDate: [{ required: true, message: 'Please select purchase date', trigger: 'blur' }],
  installationCompleteDate: [{ required: true, message: 'Please select installation completion date', trigger: 'blur' }],
  affirmCheckbox: [
    {
      validator: (rule, value, callback) => {
        if (value !== true) {
          callback(new Error('Please accept the terms and condition'))
        } else {
          callback()
        }
      },
      trigger: 'blur'
    }
  ]
})

// 格式化手机号码
export const formatPhoneNumber = (phoneNumber) => {
  let formatted = phoneNumber.replace(/\D/g, '')

  formData.phoneNo = formatted

  if (formatted.length > 4 && formatted.length <= 8) {
    formatted = formatted.slice(0, 4) + '-' + formatted.slice(4)
  } else if (formatted.length > 8) {
    formatted = formatted.slice(0, 4) + '-' + formatted.slice(4, 8) + '-' + formatted.slice(8)
  }

  return formatted
}

// 禁用未来日期
export const disabledDate = (time) => {
  return time.getTime() > Date.now()
}
