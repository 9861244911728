import { ref, reactive, defineEmits, computed, watch } from 'vue'
// 期望的来源网址列表
const expectedOrigins = ['http://localhost', 'http://190.92.207.225:8094', 'http://app.haiermall.pk']

export const fluttlerData = reactive({
  fluttlerType: ''
})

// 监听来自 Flutter 的消息
export function addMessageListener(callback) {
  if (fluttlerData.fluttlerType !== 'haierMall') return
  window.addEventListener(
    'message',
    (event) => {
      const origin = event.origin || event.originalEvent.origin
      // 检查消息的来源是否在期望的网址列表中
      const matchedOrigin = expectedOrigins.find((expectedOrigin) => origin.startsWith(expectedOrigin))
      if (!matchedOrigin) {
        console.warn(`Received message from unexpected origin: ${origin}`)
        return
      }
      let flutterData
      try {
        flutterData = JSON.parse(event.data)
      } catch (error) {
        console.error('Failed to parse message from Flutter:', error)
        return
      }
      callback(flutterData)
    },
    false
  )
}

// 向 Flutter 发送消息
export function postMessageToFlutter(message) {
  if (fluttlerData.fluttlerType !== 'haierMall') return
  window.parent.postMessage(JSON.stringify(message), '*')
}
