<script setup>
  import { formData, rules, formatPhoneNumber, disabledDate } from './zindagi-popup'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, toRef } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['prevStep', 'nextStep'])

  const formBoxRef = ref(null)

  const prevStep = () => {
    $emit('prevStep')
  }

  const toggleCheck = () => {}

  // 处理下一步操作
  const handleNext = (formBoxRef, formData) => {
    console.log(formData, 'zindagi-formData')
    formBoxRef.validate((valid, fields) => {
      if (valid) {
        console.log('Validation successful')
        // 这里可以执行其他逻辑，如提交表单数据等
        $emit('nextStep')
      }
    })
  }
</script>

<template>
  <div class="rowCC">
    <div class="columnSS form-content-box">
      <el-form ref="formBoxRef" class="form-box" label-position="top" label-width="auto" :model="formData" :rules="rules" hide-required-asterisk>
        <el-form-item prop="affirmCheckbox">
          <div class="rowSS">
            <el-checkbox class="check-box" v-model="formData.affirmCheckbox" @change="toggleCheck(index)" />
            <div class="ml10" @change="toggleCheck(index)">
              <div class="title">Customer Consent</div>
              <div class="text">
                I hereby acknowledge the terms & conditions of this offer and agree to provide accurate and complete information as required in this form. I consent to Haier
                Privacy Policy, collection, processing, and storage of my personal information for the purpose of this offer and related communications.
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="rowCC wh100 mt20">
            <el-button class="form-btn btn2" @click="prevStep"> Back </el-button>
            <el-button type="primary" class="form-btn" @click="handleNext(formBoxRef, formData)">Submit</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @import '@/assets/css/element-custom.less';
  :deep(.el-form-item__error) {
    margin-left: 24px !important;
  }

  .form-content-box {
    width: 100%;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }
  }

  .form-box {
    width: 100%;
    .check-box {
      margin-top: 25px;
    }
    .form-btn {
      min-width: 120px;
      height: 42px;
      border-radius: 24px;
      background-color: var(--btnBgColor);
      color: var(--button-text-color);
    }
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .text {
      line-height: 18px;
      font-size: 14px;
      color: rgba(43, 43, 63, 1);
    }
    .btn2 {
      background-color: var(--boxBgColor);
      color: var(--mainBgColor);
    }
  }
</style>
